import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2182060413/src/nextjs/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2182060413/src/nextjs/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2182060413/src/nextjs/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--general-sans-font\",\"src\":[{\"path\":\"../../../public/fonts/GeneralSans-Variable.ttf\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/GeneralSans-VariableItalic.ttf\",\"style\":\"italic\"}]}],\"variableName\":\"generalSansFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src2182060413/src/nextjs/src/design-system/components/Toast/Toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ICTheme"] */ "/codebuild/output/src2182060413/src/nextjs/src/design-system/ICTheme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2182060413/src/nextjs/src/lib/registry.tsx");
